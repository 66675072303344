import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import { _checkPlugin } from 'gsap/gsap-core';

let ScrollTriggerInit = gsap.registerPlugin( ScrollTrigger );

if( $( '.side-bar' ).length ) {
  ScrollTriggerInit

  const sideBarContainer = document.querySelector( '.side-bar-container' );
  const sideBar = document.querySelector( '.side-bar' );
  const sidebarSectionWrapper = document.querySelectorAll( '.sidebar-section-wrapper' );
  const infoSection = document.querySelectorAll( '.info-section' );
  const subSection = document.querySelectorAll( '.sub-section' );
  const subItem = document.querySelectorAll( '.sub-item' );
  const mobileTrigger = document.querySelector( '.mobile-trigger' );
  const openMe = document.querySelectorAll( '.open-me' )

  // Function to toggle sidebar main sections active
  function sidebarActive( section ){
    sidebarSectionWrapper.forEach( ( elem ) => {
      $( elem ).removeClass( 'active' )

      if( $( elem ).data( 'id' ) == $( section ).parent().attr( 'id' ) ) {
        $( elem ).addClass( 'active' );

        // Sidebar Scrolling Into View
        if( ( $( sideBar ).scrollTop() - $( elem ).position().top ) < -400 || ( $( sideBar ).scrollTop() - $( elem ).position().top ) > 100 ) {
          setTimeout( () => {
            $( sideBar ).animate( {
              scrollTop: $( elem ).position().top-150
            }, 750 )
          }, 500 )
        }
      }
    } )
  }

  // Function to toggle sidebar sub sections active
  function subbarActive( section ) {
    subItem.forEach( ( elem ) => {
      $( elem ).removeClass( 'sub-active' )

      if( $( elem ).data( 'id' ) == $( section ).attr( 'id' ) ) {
        $( elem ).addClass( 'sub-active' );
      }
    } )
  }

  // Mobile Sidebar toggles
  $( openMe ).on( 'click', () => {
    $( sideBarContainer ).toggleClass( 'open' )
    $( mobileTrigger ).toggleClass( 'open' )
  } )

  // Adds a Sticky Class to Sidebar when scrolling down the page  
  ScrollTrigger.create( {
    trigger: sideBarContainer,
    start: 'top-=32 top',
    end: 'bottom top',
    onEnter: () => $( sideBar ).addClass( 'sticky' ),
    onLeaveBack: () => $( sideBar ).removeClass( 'sticky' ),
  } )

  // Adds a Sticky Class to Sidebar Arrow (Mobile) when scrolling down the page  
  ScrollTrigger.create( {
    trigger: sideBarContainer,
    start: 'top top',
    end: 'bottom top',
    onEnter: () => $( mobileTrigger ).addClass( 'sticky' ),
    onLeaveBack: () => $( mobileTrigger ).removeClass( 'sticky' ),
  } )

  // Runs function to add active classes to current section
  infoSection.forEach( ( elem ) => {
    ScrollTrigger.create( {
      trigger: $( elem ).parent(),
      start: 'top-=32 top',
      end: 'bottom-=300 top',
      onEnter: () => sidebarActive( elem ),
      onEnterBack: () => sidebarActive( elem )
    } )
  } )

   // Runs function to add active classes to current section
   subSection.forEach( ( elem ) => {
    ScrollTrigger.create( {
      trigger: elem,
      start: 'top center-=200',
      end: 'bottom+=200 center-=200',
      onEnter: () => subbarActive( elem ),
      onEnterBack: () => subbarActive( elem )
    } )
  } )
}