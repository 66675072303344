import $ from 'jquery';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import Cookies from 'js-cookie';

$( function() {
  $( '.nav-handle' ).on( 'click', () => {
    $( '.nav' ).toggleClass( 'open' )
    $( 'body' ).toggleClass( 'no-scroll' );
  })
})

const settingInput = document.querySelectorAll( '.setting-input' )
const sizeSetting = document.querySelectorAll( '.size-setting' )
const settings = document.querySelector( '.settings-cog' )

// System Theme for Use System Theme Option
let systemTheme = localStorage.getItem( 'system-theme' )

// Function to set checkmark if cookie exists
function themeCheck() {
  settingInput.forEach( ( elem ) => {
    if( elem.getAttribute( 'data-theme-select' ) == Cookies.get( 'theme-select' ) ) {
      elem.checked = true
    } else {
      elem.checked = false
    }
  } )
}

// Checks to see if theres already a theme set in Local Storage and Cookie
if ( Cookies.get( 'theme-select' ) == 'system' ) {
  document.documentElement.setAttribute( 'data-theme', systemTheme )

  // Run function to set checkmark in Cookie
  themeCheck();

} else if( Cookies.get( 'theme-select' ) != undefined ) {
  document.documentElement.setAttribute( 'data-theme', Cookies.get( 'theme-select' ) )
  themeCheck();

} else {
  // Checks users preferred color scheme
  if ( window.matchMedia( '(prefers-color-scheme: dark)').matches ) {

    // Set Document Theme
    document.documentElement.setAttribute( 'data-theme', 'dark' );

    // Set Local Storage Sytem Theme
    localStorage.setItem( 'system-theme', 'dark' );

    // Set Cookie
    Cookies.set( 'theme-select', 'dark', { expires: 30 } );
  } else {
    document.documentElement.setAttribute( 'data-theme', 'light' );
    localStorage.setItem( 'system-theme', 'light' );
    Cookies.set( 'theme-select', 'light', { expires: 30 } )
  }
}

// If user is returning to site and there is a font size cookie set
if( Cookies.get( 'font-size' ) != undefined ) {
  
  // Sets doucment font size
  document.documentElement.setAttribute( 'data-font', Cookies.get( 'font-size' ) );

  // Sets font size indicator
  $( '.size-select' ).text( Cookies.get( 'font-name' ) )

  // Removes and adds active classes to font buttons
  sizeSetting.forEach( ( elem ) => {
    if( elem.getAttribute( 'data-size' ) == Cookies.get( 'font-size' ) ) {
      elem.classList.add( 'active' )
    } else {
      elem.classList.remove( 'active' )
    }
  } )
}

// Dark and Light Mode Options
$( settingInput ).on( 'input', function() {
  if( $( this ).data('theme-select') == 'system' ) {
    document.documentElement.setAttribute( 'data-theme', systemTheme );
    Cookies.set( 'theme-select', 'system', { expires: 30 } )
  } else {
    document.documentElement.setAttribute( 'data-theme', $( this ).data( 'theme-select' ) );
    Cookies.set( 'theme-select', $( this ).data( 'theme-select' ), { expires: 30 } )
  }
} )

// Font Size Options
$( sizeSetting ).on( 'click', function() {
  // Add and Remove Active Classes
  $( this ).siblings().removeClass( 'active' )
  $( this ).addClass( 'active' )

  // Change font size text
  $( '.size-select' ).text( $( this ).data( 'size-name' ) )

  // Set document font-size
  document.documentElement.setAttribute( 'data-font', $( this ).data( 'size' ) );

  // Cookies for font size and name
  Cookies.set( 'font-size', $( this ).data( 'size' ), { expires: 30 } )
  Cookies.set( 'font-name', $( this ).data( 'size-name' ), { expires: 30 } )

  // Refresh ScrollTrigger after size change
  setTimeout(() => {
    ScrollTrigger.refresh();
  }, 1000)
} )

// Open Settings Menu
$( settings ).on( 'click', () => {
  $( '.settings-box' ).toggleClass( 'open' )
  $( '.nav' ).toggleClass( 'settings-open' )
} )

