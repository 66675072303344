import $ from 'jquery';
if( $( '.sort-container' ).length ) {
  const sortNameContainer = document.querySelectorAll( '.sort-name-container' )
  const sortPillContainer = document.querySelectorAll( '.sort-pill-container' )
  const sortMe = document.querySelectorAll( '.sort-me' )
  const subContainer = document.querySelectorAll( '.sub-container' )
  const sortingItem = document.querySelectorAll( '.sorting-item' );
  const sortingChild = document.querySelectorAll( '.sorting-child' );

  // Accordion for each filtering option
  $( sortNameContainer ).on( 'click', function() {

    let $this = $( this );
    
    $this.toggleClass( 'active-name' );
    $this.siblings().removeClass( 'active-name' );

    sortPillContainer.forEach( ( elem ) => {
      $( elem ).css( 'max-height', '0' );

      if( $this.data( 'sort' ) == $( elem ).data( 'sort' ) ) {
        if( $( elem ).hasClass( 'open' ) ) {
          $( elem ).removeClass( 'open' )
        } else {
          $( elem ).siblings().removeClass( 'open' );
          $( elem ).addClass( 'open' );
          $( elem ).css( 'max-height', $( elem )[0].scrollHeight + 200);
        }
      }
    } )
  } )

  // Filter Types Function
  function resourceFilter() {
    const activeType = document.querySelectorAll( '.active-type' );
    const activeDepartment = document.querySelectorAll( '.active-department' );
    const typeContainer = document.querySelectorAll( '.type-container' );
    const resourceCard = document.querySelectorAll( '.resource-card' );
    
    // If there are active Type Filters selected
    if( $( activeType ).length ) {

      // Hide all containers
      $( typeContainer ).hide();
      
      // Loop through Active Filters list
      activeType.forEach( ( elem ) => {
        
        // Loop through Container List
        typeContainer.forEach( ( container ) => {

          // Show Container if data attributes match
          if( $( elem ).data( 'filter' ) == $( container ).data( 'type' ) ) {
            $( container ).show();
          }
        } )
  
      } )
    } else {
      $( typeContainer ).show();
    }

    // If there are active Department Filters selected
    if( $( activeDepartment ).length ) {   

      // Hide all Resource Cards
      $( resourceCard ).hide();
      $( resourceCard ).addClass( 'hidden-card' );
      $( resourceCard ).removeClass( 'filtered-card' );

      // Show All Filters
      $( sortMe ).parent().parent().show();
      
      // Loop through Active Filters list
      activeDepartment.forEach( ( department ) => {
        
        // Loop through Resource Cards List
        resourceCard.forEach( ( card ) => {

          // Show Resource Card if data attributes match
          if( $( card ).hasClass( $( department ).data( 'filter' ) ) && !$( card ).hasClass( 'filtered-card' ) ) {
            $( card ).show();
            $( card ).removeClass( 'hidden-card' )
          } else {
            $( card ).hide();
            $( card ).addClass( 'filtered-card hidden-card' );
          }
        } )
      } )

      // Loop Through Containers
      typeContainer.forEach( ( container ) => {

        // If there are no resource cards shown then hide container
        if( $( container ).find( '.resource-card' ).length == $( container ).find( '.hidden-card' ).length ) {
          $( container ).hide();

          // Hide the Type filters if there are no resource cards shown
          sortMe.forEach( ( pill ) => {
            if( $( pill ).data('filter') == $( container ).data( 'type' ) ) {
              $( pill ).parent().parent().hide();
            }
          } )
        }
      } )
    } else {
      $( resourceCard ).show();
      $( resourceCard ).removeClass( 'hidden-card' );
      $( sortMe ).parent().parent().show();
    }
  }

  

  $( sortMe ).on( 'click', function() {
    let activeFilters = 'active-filter active-' + $( this ).data('tax')

    // Add Class to run related function in resourceFilter()
    if( !$( this ).hasClass( 'active-filter' ) ) {
      $( this ).toggleClass( activeFilters );
    } else {
      $( this ).removeClass( activeFilters );
    }

    // Targetting Sub Departments
    if( $( this ).hasClass( 'sort-me-child' ) ) {
      const $sortMeParent = $( this ).parent().parent().find( '.sort-me-parent' )

      // Removes All Departments Active Filters to filter only chosen sub-department
      $( this ).siblings().removeClass( activeFilters );
      
      // Toggles filter for Parent Department
      if( $( this ).hasClass( 'active-filter' ) ) {
        $sortMeParent.removeClass( 'active-' + $( this ).data('tax') )
      } else {
        $sortMeParent.addClass( 'active-' + $( this ).data('tax') )
      }
    }

    // Shows which filtering is active on frontend
    sortingItem.forEach( ( elem ) => {
      if( $( this ).data( 'filter' ) == $( elem ).data( 'list' ) ) {
        $( elem ).toggleClass( 'show' );
      }

      if( $( this ).siblings().data( 'filter' ) == $( elem ).data( 'list' ) ) {
        $( elem ).removeClass( 'show' );
      }
    } )

    // Parent Filtering Logic
    if( $( this ).data( 'parent' ) ) {

      // Shows Child Filters if there is any
      subContainer.forEach( ( container ) => {
        if( $( container ).data( 'parent' ) == $( this ).data( 'parent' ) ) {

          if( $( container ).hasClass( 'open' ) ) {
            $( container ).removeClass( 'open' )
            $( container ).css( 'max-height', '0' );
            $( container ).css( 'margin-top', '0' );
            $( container ).find( '.sort-me' ).removeClass( activeFilters )

          } else {
            $( container ).addClass( 'open' );
            $( container ).css( 'max-height',  $( container )[0].scrollHeight + 1 );
            $( container ).css( 'margin-top', '1rem' );
          }
        }
      } )

      // Removes all active Child Filters when parent filtering is not active
      sortingChild.forEach( ( elem ) => {
        if( $( elem ).data( 'parent' ) == $( this ).data( 'parent' ) ) {
          $( elem ).removeClass( 'show' );
        }
      } )
    }

    resourceFilter();
  } )

  $( sortingItem ).on( 'click', function() {
    
    $( this ).removeClass( 'show' );

    sortMe.forEach( ( elem ) => {
      let activeFilters = 'active-filter active-' + $( elem ).data( 'tax' );

      if( $( this ).data( 'list' ) == $( elem ).data( 'filter' ) ) {
        $( elem ).removeClass( activeFilters )
        // Parent Filtering Logic
        if( $( elem ).data( 'parent' ) ) {
  
          // Shows Child Filters if there is any
          subContainer.forEach( ( container ) => {
            if( $( container ).data( 'parent' ) == $( elem ).data( 'parent' ) ) {
  
              if( $( container ).hasClass( 'open' ) ) {
                $( container ).removeClass( 'open' )
                $( container ).css( 'max-height', '0' );
                $( container ).css( 'margin-top', '0' );
                $( container ).find( '.sort-me' ).removeClass( activeFilters )
  
              } else {
                $( container ).addClass( 'open' );
                $( container ).css( 'max-height',  $( container )[0].scrollHeight + 1 );
                $( container ).css( 'margin-top', '1rem' );
              }
            }
          } )
  
          // Removes all active Child Filters when parent filtering is not active
          sortingChild.forEach( ( elem ) => {
            if( $( elem ).data( 'parent' ) == $( this ).data( 'parent' ) ) {
              $( elem ).removeClass( 'show' );
            }
          } )
        }
      }

    } )

    resourceFilter();
  } )

}
